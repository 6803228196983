<template>

    <div class="w-full h-full px-3 py-4">

        <div class="absolute top-0 left-0 w-full h-20 z-20" 
        :class="{'mt-24': isVisible || searchInput, '-mt-20': !isVisible && !searchInput}"
        style="transition: all .3s">
            <div class="h-full w-full flex flex-col justify-end items-center p-2" :class="'bg-module-'+mode">
                <input type="text" v-model="searchInput" :placeholder="$t('buscarcliente')" class="w-full h-10 px-2 rounded-xl" :class="'bg-box-'+mode+' text-dfont-'+mode">
            </div>
        </div>

        <!-- <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">

            <div class="h-16 flex flex-row">

                <div class="h-full w-10/12 flex flex-row">

                    <div class="h-full w-3/12 flex flex-row justify-center items-center">
                        <vue-initials-img :name="zone.Name" class="h-12 w-12 rounded-full"/>
                    </div>

                    <div class="h-full w-9/12 flex flex-col justify-center items-start truncate">
                        <span class="text-lg font-semibold truncate" :class="'text-dfont-'+mode">{{$t('zone')}} {{zone.Name}}</span>
                    </div>

                </div>

                <div class="h-full w-2/12 flex flex-col justify-start items-end">
                    <i class="mdi mdi-dots-vertical text-gray text-xl" @click="goFilters()"></i>
                </div>

            </div>
            <div class="flex justify-start items-center">
                <div class="h-10 flex flex-row justify-start items-center pl-4">
                    <span class="text-md text-darkgray">{{$t('customers')}} <span class="text-purple font-semibold mr-2">{{period}}</span></span>
                </div>
                <span v-if="periodo == 'Mensual'" class="text-md text-gray">Mes: <span class="text-md text-darkgray font-semibold capitalize">{{ monthName }}</span></span>
            </div>

            <div class="h-8 px-4">
                <div class="h-full rounded-lg flex flex-row justify-start items-center px-2" :class="'bg-box-'+mode">
                    <i class="mdi mdi-magnify text-gray mr-2"></i>
                    <input type="text" v-model="searchInput" :class="'bg-box-'+mode+ ' text-dfont-'+mode">
                </div>
            </div>

            <div class="h-6"></div>

        </div> -->

        <All :model="model" :immediate="true" :config="config" :query="query" v-slot="{data, loading}">

            <div v-if="!loading" class="h-auto" :class="{'pt-12': searchInput != ''}">

                <Intersect @enter="onEnter" @leave="onLeave" >
                    <div class="h-1"></div>
                </Intersect>

                <div v-if="filteredComercials(data) && filteredComercials(data).length > 0" class="h-auto">
                    <client v-for="(el,index) in filteredComercials(data)" :key="index" :data="el"  :reducetype="false" father="comercial"></client>
                </div>

                <div v-else class="h-32 flex mt-6 flex-col justify-center items-center">
                    <span :class="'text-font_gray-'+mode">{{ $t('noData') }}</span>
                </div>

            </div>

            <div v-else class="h-32 relative">
                <loader :loading="loading"></loader>
            </div>

        </All>

    </div>

</template>

<script>
import columnchart from '../../components/columnchart.vue';
import { state, actions } from '@/store';
import { All } from '@/api/components';
import loader from '@/components/loader.vue';
import client from '@/components/client.vue';
import Intersect from 'vue-intersect';

export default {
    components:{
        columnchart,
        All,
        loader,
        client,
        Intersect
    },
    data(){
        return{
            searchInput: '',
            isVisible: false
        }
    },
    methods:{
        onLeave(){
            this.isVisible = true
        },
        onEnter(){
            this.isVisible = false
        },
        goToClient(client){
            actions.setClientSelected(client)
            this.$router.push('client')
        },
        filteredComercials(data) {
            return Object.values(data).filter(post => {
                return post['Name'].toLowerCase().includes(this.searchInput.toLowerCase())
            })
        },
        colortype(type){

            switch (type) {

                case 'ALMACENISTAS':
                    return 'blue'
                    break;
                case 'PARTICULARES':
                    return 'purple'
                    break;
                case 'CONSTRUCTORAS':
                    return 'brown'
                    break;
            }

        },
        goFilters(){
            this.$router.push({ name: 'filters' })
        }
    },
    computed:{
        monthName(){
            return this.$moment({M: state.month -1 }).format('MMMM')
        },
        comercial(){
            return state.comercialSelected
        },
        model(){
            // if(this.comercial.Id){
            //     return 'ClientsUser'
            // } else  {
            //     return 'ClientsZone'
            // }
            return 'ClientsZone'
        },
        config(){

            if(state.user.rol.Name != 'Comercial'){

                return {
                    data:{
                        Company: state.idCompany,
                        SaleType: state.saleTypeSelected,
                        period: state.period,
                        Zone: state.idZone,
                        Dimension: state.dimensionGroup
                    }
                }

            } else {

                return {
                    data:{
                        Company: state.idCompany,
                        SaleType: state.saleTypeSelected,
                        period: state.period,
                        Zone: state.idZone,
                        Dimension: state.dimensionGroup
                    }
                }

            }
        },
        period() {
            switch(state.period) {
                case 'year':
                    return this.$t("annual")
                    break;
                case 'quarter':
                    return this.$t("quarterly")
                    break;
                default:
                    return this.$t("monthly")
            }
            return state.period
        },
        query(){
            return {
                period: state.period,
                IndustrialGroup: state.industrialGroup,
                month: state.month
            }
        },
        zone(){
            return state.zone
        },
        mode(){
            return state.mode
        }
    }
}
</script>
